<template>
  <div class="addCoinBox">
    <NavBar title="充值">
      <template #right>
        <TextBox color="#333333" :font-size="26" @click.native="goRecord">记录</TextBox>
      </template>
    </NavBar>
    <div class="contentBox">
      <Space :height="30"></Space>
      <van-field
        type="text"
        :value="coin && coin.name"
        readonly
        class="bg radius10 targetCoin"
        right-icon="arrow-down"
        @click="showTargetCoinPicker = true"
      />
      <van-popup v-model="showTargetCoinPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="coinList.map(item => item.name)"
          @cancel="showTargetCoinPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <Space :height="50"></Space>
      <div class="pd24">
        <TextBox color="title">链类型</TextBox>
        <Space :height="27"></Space>
        <div class="linkBox">
          <div
            :class="{ active: chainActive === index }"
            v-for="(item, index) of chainList"
            :key="index"
            @click="chainActive = index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="dfjccaic fdc">
        <Space :height="50"></Space>
        <div ref="qrcode" class="qrcode"></div>
        <Space :height="15"></Space>
        <TextBox color="intro" :font-size="24">扫描二维码获取充值地址</TextBox>
        <Space :height="18"></Space>
        <van-button type="primary" plain class="reset saveButton" @click="saveImage">{{
          $isWeiXin() ? '长按图片保存本地' : '保存本地'
        }}</van-button>
        <Space :height="27"></Space>
        <TextBox color="intro">充值地址</TextBox>
        <Space :height="19"></Space>
        <div class="siteBox dfjccaic">
          <TextBox color="title" class="mr34 siteLinkBox">{{ link }}</TextBox>
          <van-image
            :width="parseInt($pxToPxRatio(31), 10)"
            :height="parseInt($pxToPxRatio(31), 10)"
            lazy-load
            fit="contain"
            :src="require('@/assets/images/personal/myAssets/b1.png')"
            v-clipboard:copy="link"
            v-clipboard:success="() => $toast('复制成功')"
            v-clipboard:error="() => $toast('复制失败')"
          />
        </div>
      </div>
      <Space :height="53"></Space>
      <van-divider />
      <Space :height="50"></Space>
      <div class="pd24">
        <TextBox color="title">上传充值凭证</TextBox>
        <Space :height="27"></Space>
        <van-uploader
          v-model="fileList"
          :upload-icon="prove.before"
          :preview-size="parseInt($pxToPxRatio(226), 10)"
          :after-read="uploadFile"
          :max-count="1"
        />
        <Space :height="28"></Space>
        <TextBox color="danger" :font-size="22">*上传时请务必拍清楚交易哈希、交易时间、充值数量。</TextBox>
      </div>
      <Space :height="50"></Space>
      <TextBox color="intro1" :font-size="24">注意事项：</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="24" style="line-height: 1.8;">
        ·请勿向上述地址充值非{{ coin.name }}资产，否则资产将不可找回。<br />
        ·单笔最小充值金额：1{{ coin.name }}，小于最小金额的充值将不会到账且无法退回。<br />
        ·你的充值地址不会经常改变，可以重复充值；如有变更，我们会尽量通过公告或邮件通知您。<br />
        ·请务必确认电脑及浏览器安全，防止信息被篡改及泄露。<br />
      </TextBox>
      <Space :height="50"></Space>
      <van-button type="primary" block @click="submit" :loading="$store.state.submitLoading">充值</van-button>
      <Space :height="65"></Space>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      fileList: [],
      showTargetCoinPicker: false,
      coinActive: 0,
      coinList: [],
      chainActive: 0,
      chainList: [],
      codeImage: require('@/assets/images/personal/myAssets/a1.png'),
      link: '',
      // 充值凭证
      prove: {
        before: require('@/assets/images/personal/myAssets/b2.png'),
        after: '',
      },
    };
  },
  created() {
    this.getCoinList();
  },
  // mounted() {},
  methods: {
    saveImage: _.debounce(function() {
      let imgUrl = this.$refs.qrcode.childNodes[0].toDataURL('image/png');
      if (this.$judgePhone() === 'A' && window.ipfshyys) {
        this.$http('post', '/v1/cdn/uploadImgBase64', {
          base64: imgUrl,
        }).then(res => {
          ipfshyys.saveToAlbum(res.data);
        });
        return;
      }

      let saveLink = document.createElement('a');
      saveLink.href = imgUrl;
      saveLink.download = '充值.png';
      saveLink.click();
    }),
    uploadFile(file) {
      if (file.file.size > 10 * 1024 * 1024) {
        this.$toast('图片大小不能超过10M');
        return;
      }
      file.status = 'uploading';
      file.message = '上传中...';
      this.$http('file', '/v1/cdn/uploadImg', {
        file: file.file,
      })
        .then(res => {
          file.status = '';
          file.message = '';
          this.prove.after = res.data;
        })
        .catch(() => {
          file.status = 'failed';
          file.message = '上传失败';
        });
    },
    getCoinList() {
      this.$http('get', '/v1/assets/coinInfo').then(res => {
        let list = res.data.filter(item => {
          return item.isDeposit === 1;
        });
        this.coinList = list;
        let urlCoinId = this.$route.query.coinId;
        if (urlCoinId) {
          list.forEach((item, index) => {
            if (item.id === Number(urlCoinId)) {
              this.coinActive = index;
            }
          });
        }
      });
    },
    submit: _.debounce(function() {
      if (!this.prove.after) {
        this.$toast('充值凭证不能为空');
        return;
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/assets/deposit', {
        chainType: this.chainList[this.chainActive],
        coinId: this.coin.id,
        fromAddress: '',
        toAddress: this.link,
        url: this.prove.after,
        walletAccountId: this.$store.state.personal.userActive ? 3 : 1,
      })
        .then(res => {
          let that = this;
          this.$toast('提交成功');
          that.$router.back();
          this.prove.after = '';
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
    goRecord() {
      this.$router.push(`/personal/myAssets/addGetRecord?coinId=${this.coin.id}`);
    },
    onConfirm(value, index) {
      this.coinActive = index;
      this.showTargetCoinPicker = false;
    },
    qrcode() {
      this.$nextTick(() => {
        this.$refs.qrcode.innerHTML = '';
        new QRCode(this.$refs.qrcode, {
          width: parseInt(this.$pxToPxRatio(260), 10),
          height: parseInt(this.$pxToPxRatio(260), 10),
          text: this.link,
        });
      });
    },
    updateChainList() {
      this.prove.after = '';
      this.fileList = [];
      if (this.coin.name.toUpperCase() === 'FIL') {
        this.chainList = ['FIL'];
        this.chainActive = 0;
      } else {
        // this.chainList = ['ERC20', 'TRC20'];
        this.chainList = ['ERC20'];
        this.chainActive = 0;
      }
      let coin = this.coinList[this.coinActive];
      if (coin.name.toUpperCase() === 'FIL') {
        this.link = 'f1dspaybcyk3qzh65x543pol6uivqnbudpy5kyqwi';
      }
      if (coin.name.toUpperCase() === 'USDT') {
        this.link = '0x00211C38616d07f5184F6Ee51aA2905Ab0491D98';
      }
    },
  },
  watch: {
    fileList(res) {
      if (!res.length) {
        this.prove.after = '';
      }
    },
    link: {
      handler(res) {
        if (res) {
          this.qrcode();
        }
      },
      immediate: true,
    },
    coinActive(res) {
      this.updateChainList();
    },
    coinList(res) {
      this.updateChainList();
    },
  },
  computed: {
    coin() {
      return (
        this.coinList[this.coinActive] || {
          name: '',
        }
      );
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.addCoinBox {
  /deep/ .van-uploader {
    .van-uploader__upload {
      background-color: transparent;
      .van-icon__image {
        width: 100%;
        height: 100%;
      }
    }
    .van-uploader__preview {
      .van-uploader__preview-image {
        border-radius: 10px;
      }
      .van-uploader__preview-delete {
        border-radius: 50%;
        width: 31px;
        height: 31px;
        background-color: #ef4e4e;
        top: -10px;
        right: -10px;
        box-shadow: 0 0 0 4px white;
        .dfjccaic;
        .van-uploader__preview-delete-icon {
          position: relative;
          top: 0;
          right: 0;
          transform: none;
          font-size: 22px;
          font-weight: 600;
          margin-left: 3px;
        }
      }
    }
  }
  .targetCoin {
    font-weight: bold;
    font-size: 36px;
  }
  .linkBox {
    display: flex;
    > div {
      padding: 11px 24px;
      background: rgba(168, 168, 181, 0.12);
      border-radius: 10px;
      font-size: 28px;
      font-weight: 400;
      color: #353470;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      line-height: 40px;
    }
    .active {
      background: #525298;
      color: @white;
    }
  }
  .qrcode {
    width: 260px;
    height: 260px;
    img {
      user-select: all;
    }
  }
  .saveButton {
    height: 60px;
  }
}
.siteLinkBox {
  width: 500px;
  word-break: break-all;
  text-align: center;
}
</style>
